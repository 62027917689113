import { render, staticRenderFns } from "./PackageDetailPage.vue?vue&type=template&id=50c32ba2&scoped=true"
import script from "./PackageDetailPage.vue?vue&type=script&lang=ts"
export * from "./PackageDetailPage.vue?vue&type=script&lang=ts"
import style0 from "./PackageDetailPage.vue?vue&type=style&index=0&id=50c32ba2&prod&scoped=true&lang=css"
import style1 from "./PackageDetailPage.vue?vue&type=style&index=1&id=50c32ba2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c32ba2",
  null
  
)

export default component.exports