import { Utils, ZeroSellPriceDisplay } from "chipply-common";
import IPackageDto from "@/chipply/i-package-dto";

export class PackageHelper {
    public determineUpdatedPackagePriceDisplay(updatedPrice: number, packageDto: IPackageDto) {
        const args = this.checkAllItemsSellPriceDisplay(packageDto);
        const newPriceIsZero = updatedPrice === 0;
        if (args.allCustom && newPriceIsZero) {
            return packageDto.products.products[0].zeroSellPriceLabel;
        } else if (args.allDoNotDisplay && newPriceIsZero) {
            return "";
        } else if (args.allDisplayAsZero && newPriceIsZero) {
            return "$0.00";
        } else {
            return Utils.toCurrency(updatedPrice);
        }
    }

    public determineZeroSellPriceDisplay(packageDto: IPackageDto) {
        const displayPrice = Utils.toCurrency(packageDto.price);
        const args = this.checkAllItemsSellPriceDisplay(packageDto);

        const packageHasProducts = packageDto.products.products.length > 0;
        if (args.allCustom && packageHasProducts) {
            return packageDto.products.products[0].zeroSellPriceLabel;
        } else if (args.allDoNotDisplay && packageHasProducts) {
            return packageDto.products.products.every((product) => product.basePrice == 0) ? "" : displayPrice;
        } else if (args.allDisplayAsZero && packageHasProducts) {
            return packageDto.products.products.every((product) => product.basePrice == 0) ? "$0.00" : displayPrice;
        } else {
            return displayPrice;
        }
    }

    public checkAllItemsSellPriceDisplay(packageDto: IPackageDto) {
        const allCustom = packageDto.products.products.every(
            (product) => product.zeroSellPriceDisplay === ZeroSellPriceDisplay.CustomLabel
        );
        const allDoNotDisplay = packageDto.products.products.every(
            (product) => product.zeroSellPriceDisplay === ZeroSellPriceDisplay.DoNotDisplay
        );
        const allDisplayAsZero = packageDto.products.products.every(
            (product) => product.zeroSellPriceDisplay === ZeroSellPriceDisplay.DisplayAsZero
        );

        return { allCustom: allCustom, allDoNotDisplay: allDoNotDisplay, allDisplayAsZero: allDisplayAsZero };
    }
}
