






























































































import IProductDto from "@/chipply/i-product-dto";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import IPackageDto from "@/chipply/i-package-dto";
import { Utils } from "chipply-common";
import PackageDesignFlag from "./PackageDesignFlag.vue";
import { ZeroSellPriceDisplay } from "chipply-common";
import IndividualDisplayCard from "@/components/IndividualDisplayCard.vue";
import ProductDisplayCard from "@/components/ProductDisplayCard.vue";
import ProductImageSlider from "@/components/design-elements/ProductImageSlider.vue";
import { PackageHelper } from "@/chipply/view-model/PackageHelper";

@Component({
    components: {
        ProductImageSlider,
        ProductDisplayCard,
        IndividualDisplayCard,
        PackageDesignFlag,
    },
})
export default class StorePackage extends Vue {
    public Utils = Utils;
    public helper = new PackageHelper();

    @Prop({
        default: "",
        type: String,
    })
    public foregroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public backgroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public eventID!: string;

    @Prop()
    public packageDto!: IPackageDto;

    public imagesShown() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return 5;
        } else {
            return 3;
        }
    }

    public buttonWidth() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return 215;
        } else {
            return 100;
        }
    }

    public getProductName(product: IProductDto) {
        let productDisplay = "";
        if (!product.hideVendor) {
            productDisplay += product.vendorName;
        }
        if (!product.hideStyle) {
            if (productDisplay.length > 0) {
                productDisplay += " - ";
            }
            productDisplay += product.sku;
        }

        if (!product.hideProductName) {
            if (productDisplay.length > 0) {
                productDisplay += " - ";
            }

            productDisplay += product.productName;
        }

        return productDisplay;
    }
}
